import * as React from "react";
import image from '../../images/document.png';
import image2 from '../../images/document2.png';
import image3 from '../../images/document3.png';
import Project from '../../Layouts/project';

const { Fragment } = React;

const Documents = () => {
  return (
    <Project 
      title="Guess that Document" 
      images={[image, image2, image3]}
      link="https://fathom.info/guessthatdoc/"
      description={<Fragment>While interning at Fathom I worked on <a href="https://medium.com/fathominfo/useful-document-summary-stats-13d99855b95e" target="_blank">a project</a> visualizing aspects of large document collections.  This little demo is a simple game to match documents with some of the stats I was experimenting with.</Fragment>} 
      />)
}

export default Documents
